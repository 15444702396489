import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import spins from "content/images/about-spins.svg"

const saasImg = "https://hrflow-ai.imgix.net/labs/saas.png?w=690&h=400&fit=clip"

const StyledCard = styled.div`
  margin: auto;
  margin-top: -10.5rem;
  background: url(${spins}), linear-gradient(268.65deg, #16385A 71.18%, #07192C 94.99%);
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 81.9rem;
    border-radius: 0.625rem;
  }
  .content {
    padding: 4.37rem 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 4.37rem;
    }
    text-align: left;
    .title {
      font-size: 1.75rem;
      line-height: 2.2rem;
      color: white;
      margin-bottom: 1rem;
    }
    .text {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 300;
      font-size: 0.87rem;
      margin-bottom: 1.62rem;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    a:first-of-type {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    a {
      width: 100%;
      text-align: center;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      a:first-of-type {
        margin-bottom: 0;
        margin-right: 1rem;
      }
      a {
        width: unset;
      }
    }
  }
  .image {
    display: none;
    background-image: url(${saasImg});
    background-size: auto;
    margin-top: 1rem;
    background-position: bottom;
    background-repeat: no-repeat;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
      border-bottom-right-radius: 0.625rem;
    }
  }
  .image-el {
    display: block; 
    width: 90%;
    height: auto;
    margin: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: none;
    }
  }
`

const StyledButton = styled(Link)`
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding: 1rem;  
  background: ${({ theme }) => theme.colors.estern};
  border-radius: 0.375rem;
  color: white;
`
const StyledButtonSecondary = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.zodiacLight};
  color: ${({ theme }) => theme.colors.primary};
`

const About = ({ color, icon, title, description }) => (
  <StyledCard>
    <div className="content">
      <div className="section-label">ABOUT US</div>
      <div className="title">
        The definitive AI-Powered API <br /> for Human Resources
      </div>
      <div className="text">
        Extract, Enrich & Qualify HR Data. <br />
        Connect your apps & automate workflows.
      </div>
      <div className="buttons">
        <StyledButton to="https://hrflow.ai/book-us" rel="noopener" target="_blank" >Talk to an expert</StyledButton>
        <StyledButtonSecondary to="https://hrflow.ai/signup" rel="noopener" target="_blank">Sign up</StyledButtonSecondary>
      </div>
    </div>
    <div className="image">
    </div>
    <img className="image-el" src={saasImg} alt="saas image" />
  </StyledCard>
)

export default About
