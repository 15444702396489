import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledBtn = styled(Link)`
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  &:hover {
    cursor: pointer;
    outline: none;
    transform: translateY(-1px);
    opacity: 0.8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  }
`

export default StyledBtn
