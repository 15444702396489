import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from "content/favicon.png"

const SEO = ({ frontmatter }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js?render=6Lcbn24bAAAAADaaYP7VaaG6FYlzzFT0sAZUvqjg";
    script.async = true;
    document.body.appendChild(script);

    var zi = document.createElement('script');
          zi.type = 'text/javascript';
          zi.async = true;
          zi.referrerPolicy = 'unsafe-url';
          zi.src = 'https://ws.zoominfo.com/pixel/62e2f6695d30f0008e3f7f63';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(zi, s);
  }, [])
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={frontmatter.seoTitle}
      titleTemplate={`%s`}
      link={[{ rel: 'canonical', key: frontmatter.url, href: frontmatter.url }]}
      meta={[
        {
          name: `description`,
          content: frontmatter.description,
        },
        {
          property: `og:title`,
          content: frontmatter.seoTitle,
        },
        {
          property: `og:url`,
          content: frontmatter.url,
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:site_name`,
          content: frontmatter.seoTitle,
        },
        {
          property: `og:description`,
          content: frontmatter.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: frontmatter.seoImage || "https://hrflow-ai.imgix.net/labs/seo/labs.png",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator:id`,
          content: "7999258",
        },
        {
          name: `twitter:creator`,
          content: "HrFlow.ai Engineering",
        },
        {
          name: `twitter:title`,
          content: frontmatter.seoTitle,
        },
        {
          name: `google-analytics`,
          content: "UA-177892673-1",
        },
        {
          name: `twitter:description`,
          content: frontmatter.description,
        },
        {
          property: `twitter:site:id`,
          content: '7999258',
        },
        {
          name: `msapplication-TileColor`,
          content: "#9DB9D6",
        },
        {
          name: `msapplication-TileImage`,
          content: `${favicon}`,
        },
        {
          name: `theme-color`,
          content: "#9DB9D6",
        },
        {
          name: `twitter:image:width`,
          content: "1200",
        },
        {
          name: `twitter:image:height`,
          content: "600",
        },
        {
          name: `pjax-timeout`,
          content: '1000',
        },
        {
          name: `twitter:image`,
          content: frontmatter.seoImage || "https://hrflow-ai.imgix.net/labs/seo/labs.png",
        },
        {
          name: `twitter:image:src`,
          content: frontmatter.seoImage ||  "https://hrflow-ai.imgix.net/labs/seo/labs.png",
        },
        {
          name: `organization`,
          content: "Hrflow.ai",
        },
        {
          name: `organization url`,
          content: "https://hrflow.ai/",
        },
        {
          name: `organization logo`,
          content: "https://hrflow-ai.imgix.net/logo.svg",
        },
        {
          property: `twitter:site`,
          content: `@hrflowai`,
        },
        {
          property: `fb:app_id`,
          content: "258550260969898",
        },
        {
          "http-equiv": 'cleartype',
          content: 'on'
        },
    /*     {
          name: 'ahrefs-site-verification',
          content: '3197aa44a924060b532e006bd27a57cfdab64aacb55496ef8b446bd5ea2b9ace'
        } */
        {
          property: `ix:srcAttribute`,
          content: `data-src`,
        },
        {
          property: `ix:srcsetAttribute`,
          content: "data-srcset",
        },
        {
          property: 'ix:sizesAttribute',
          content: 'data-sizes'
        }
      ]}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  lang: PropTypes.string,
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
