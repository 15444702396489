import React from 'react'
import styled from 'styled-components'
import About from "components/common/about"
import logoBottom from 'content/images/logo.svg'
import mediumIcon from 'content/images/medium.svg'
import twitterIcon from 'content/images/twitter.svg'
import facebookIcon from 'content/images/facebook.svg'
import instagramIcon from 'content/images/instagram.svg'
import linkedinIcon from 'content/images/linkedin.svg'
import rgdpImg from 'content/images/rgdp.svg'

import ContentWrapper from "styles/contentWrapper"


const FooterSection = styled.footer`
    background-color: ${({ theme }) => theme.colors.zodiacDark};
    color: white;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    padding-top: 3rem;
  }
`

const FooterTop = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
`
const Left = styled.div`
    display: flex;
    margin-left: 3rem;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
        margin-left: 0;
        margin-bottom: 3rem;
    }
`

const Right = styled.div`
    display: flex;
    justify-content: flex-end;
`

const Divider = styled.div`
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, .8);
    margin: 2.5rem 0 1.5rem 0;
`

const Title = styled.div`
    font-size: 0.9rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .065rem;
    color: ${({ theme }) => theme.colors.estern};;
    margin-bottom: 1rem;
    margin-left: 5rem;
    @media (max-width: 1500px) {
        margin-left: 2.5rem
    }
    @media (max-width: 1200px) {
        margin-right: 1rem;
        margin-left: 0;
        min-width: 8rem;

    }
`

const Items = styled.ul`
    list-style: none;
    margin-left: 5rem;
    padding-left: 0;
    @media (max-width: 1500px) {
        margin-left: 2.5rem
    }
    @media (max-width: 1200px) {
        margin-right: 1rem;
        margin-left: 0;
        margin-bottom: 2rem;
        min-width: 8rem;
    }
`

const Item = styled.li`
    margin-bottom: .5rem;
    opacity: .8;
    font-size: .8rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6rem;
    letter-spacing: -.015rem;
    color: #fff;
    white-space: nowrap;
    a {
        color: unset!important;
        text-decoration: none!important;
    }

`

const Text = styled.div`
  margin-bottom: .5rem;
  opacity: .8;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: -.015rem;
  color: #fff;
  margin-bottom: 1rem;
  margin-top: .4rem;
  max-width: 300px;
  @media (max-width: 1200px) {
      margin-bottom: 2rem;
  }

`

const FooterBottomWrapper = styled.div`
  max-width: 100%;
  background: #1A8E94;
`

const FooterBottom = styled(ContentWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  margin-top: 3.5rem;
  @media (max-width: 1200px) {
      flex-direction: column;
  }
`

const LogoImg = styled.img`
    height: 3.05rem;
    width: 8.25rem;
`

const Social = styled.div`
    display: flex;
    justify-content: space-between;
    a {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1DAFB6;
        border-radius: 0.25rem;
        margin: 0 0.2rem;
    }
    @media (max-width: 1200px) {
        margin: 1rem 0;
        max-width: 400px;
    }
`

const Icon = styled.img`
    height: .85rem;
`

const TextRight = styled.div`
    font-size: .9rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.015rem;
    text-align: right;
    color: #fff;
    text-align: left;
    margin-left: 0.5rem;
`

const Footer = () => {
    return (
      <FooterSection>
          <About />
          <StyledContentWrapper>
              <FooterTop>
                  <Right>
                    <div>
                      <LogoImg style={{ marginBottom: '1rem' }} src={logoBottom} alt="logo" />

                      <div>
                          <Text>
                            <a href="https://hrflow.ai">HrFlow.ai</a> is an API-first company and the leading AI-powered HR data automation platform.<p>The company helps +1000 customers (HR software vendors, Staffing agencies, large employers, and headhunting firms) to thrive in a high-volume and high-frequency labor market.</p><p>The platform provides a complete and fully integrated suite of HR data processing products based on the analysis of hundreds of millions of career paths worldwide -- such as Parsing API, Tagging API, Embedding API, Searching API, Scoring API, and Reasoning API. It also offers a catalog of +200 connectors to build custom scenarios that can automate any business logic.</p>
                          </Text>
                          <div>
                            <LogoImg src={rgdpImg} alt="logo" />
                            </div>
                      </div>
                    </div>
                  </Right>
                  <Left>
                      <div>
                        <Title>
                            SOLUTION
                        </Title>
                        <Items>
                          <Item><a rel="noopener" href="https://hrflow.ai/developers">For HR Vendors & HrTech</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/corporates">For Large employers</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/use-cases">Use Cases</a></Item>
                          <Item><a href="https://status.hrflow.ai/customers">Customers' stories</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/pricing">Pricing</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/book-us">Book a demo</a></Item>
                        </Items>
                      </div>
                      <div>
                        <Title>
                            PRODUCT
                        </Title>
                        <Items>
                          <Item><a rel="noopener" href="https://hrflow.ai/api">How it works</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/parsing">Parsing API</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/tagging">Tagging API</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/embedding">Embedding API</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/searching">Searching API</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/scoring">Scoring API</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/upskilling">Upskilling API</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/sources">Source Connectors</a></Item>
                          <Item><a rel="noopener" href="https://hrflow.ai/destinations">Destination Connectors</a></Item>
                        </Items>
                      </div>
                      <div>
                          <Title>
                              RESOURCES
                          </Title>
                          <Items>
                            <Item><a href="#">See AI in action</a></Item>
                            <Item><a href="https://developers.hrflow.ai/">API documentation</a></Item>
                            <Item><a href="https://www.youtube.com/channel/UCb6YzPCNnGEPTfrX-GmQyTg">Youtube channel</a></Item>
                            <Item><a href="https://help.hrflow.ai/">Help Center</a></Item>
                            <Item><a href="https://updates.hrflow.ai">Product updates</a></Item>
                            <Item><a href="https://updates.hrflow.ai/">Blog</a></Item>
                            {/* <Item><a href="https://app.livestorm.co/hrflow">Events</a></Item> */}
                            <Item><a href="https://communityinviter.com/apps/hrarchitect/join">Join our Slack community</a></Item>
                          </Items>
                      </div>
                      <div>
                          <Title>
                              COMPANY
                          </Title>
                          <Items>
                              <Item><a rel="noopener" href="https://hrflow.ai/about-us">Our story</a></Item>
                              <Item><a rel="noopener" href="https://hrflow.ai/careers">We are hiring!</a></Item>
                              <Item><a rel="noopener" href="https://hrflow.ai/awards">Awards</a></Item>
                              <Item><a rel="noopener" href="https://hrflow.ai/press">press</a></Item>
                              <Item><a href="https://security.hrflow.ai/">Security</a></Item>
                              <Item><a href="https://gdpr.hrflow.ai/">G.D.P.R Hub</a></Item>
                              <Item><a rel="noopener" href="https://hrflow.ai/partners">Partners' portal</a></Item>
                              <Item><a  href="https://status.hrflow.ai/">Platform status</a></Item>
                          </Items>
                      </div>
                  </Left>
              </FooterTop>
          </StyledContentWrapper>
          <FooterBottomWrapper>
            <FooterBottom>
              <TextRight>
                Copyright © 2021 HrFlow.ai - Privacy Policy
              </TextRight>
              <Social>
                  <a target="blank" rel="nofollow" href="http://linkedin.com/company/hrflowai"><Icon src={linkedinIcon} className="social-logo" alt="linkedin logo"/></a>
                  <a target="blank" rel="nofollow" href="https://twitter.com/hrflowai"><Icon src={twitterIcon} className="social-logo" alt="twitter logo" /></a>  
                  <a target="blank" rel="nofollow" href="https://www.facebook.com/hrflowai"><Icon src={facebookIcon} className="social-logo" alt="fb logo" /></a>
                  <a target="blank" rel="nofollow" href="https://medium.riminder.net/"><Icon src={mediumIcon} className="social-logo" alt=" medium logo" /></a>  
                  <a target="blank" rel="nofollow" href="https://www.instagram.com/hrflowai/"><Icon src={instagramIcon} className="social-logo" alt=" instagram logo"  /></a>            
              </Social>
            </FooterBottom>
        </FooterBottomWrapper>
      </FooterSection>
    )
}

export default Footer;
