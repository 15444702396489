import { createGlobalStyle } from "styled-components"
import modernNormalize from "styled-modern-normalize"
import basierCircle from "../fonts/basiercircle-regular.woff"

const GlobalStyle = createGlobalStyle`
    
  // Import normalize.css

  @font-face {
    font-family: basier-circle;
    src: url(${basierCircle}) format("woff");
  }
  @media (min-width:1200px) {
    :root {
      font-size: 11.25px
    }
  }

  @media (min-width:1300px) {
    :root {
      font-size: 12.2px
    }
  }

  @media (min-width:1400px) {
    :root {
      font-size: 13.1px
    }
  }

  @media (min-width:1500px) {
    :root {
      font-size: 13.72px
    }
  }

  @media (min-width:1600px) {
    :root {
      font-size: 15px
    }
  }

  @media (min-width:1700px) {
    :root {
      font-size: 16px
    }
  }

  @media (min-width:1800px) {
    :root {
      font-size: 16.87px
    }
  }

  @media (min-width:1900px) {
    :root {
      font-size: 18px
    }
  }
  
  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.solitudeLight};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    line-height: 1.7rem;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    &.blur {
        overflow: hidden;
        #___gatsby #main-content > * {
          filter: blur(5px) ;
          transition: all .3s ease-out;
          pointer-events: none;
          user-select: none;
        }
      }
    }
    &.splashScreen {
          position: fixed;
          overflow: hidden;
    }

  a {
      display: inline-block;
      text-decoration: none;
      text-decoration-skip-ink: auto;
      color: inherit;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: 0;
      }
  }

  h1 {
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.375rem;
      color: ${({ theme }) => theme.colors.primary};
      margin: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 3.125rem;
          line-height: 3.875rem;
      }
  }

  h2 {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.colors.primary};
      margin: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 1.625rem;
          line-height: 2rem;
      }
  }

  h3 {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2.25rem;
      color: ${({ theme }) => theme.colors.primary};
      margin-bottom: 3rem;
  }

  h4 {
      font-size: 1.1875rem;
      line-height: 1.8rem;
      font-weight: 400;
      margin: 0;
  }

  hr {
    margin: 3rem auto;
    border-width: .05rem;
    color: ${({ theme }) => theme.colors.tertiary};
    opacity: 0.1;
  }

  .mt-05 {
    margin-top: 0.5rem;
  }

  .mr-05 {
    margin-right: 0.5rem;
  }

  .mb-05 {
    margin-bottom: 0.5rem;
  }

  .ml-05 {
    margin-left: 0.5rem;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mr-1 {
    margin-right: 1rem;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .ml-1 {
    margin-left: 1rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .mr-2 {
    margin-right: 2rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .ml-2 {
    margin-left: 2rem;
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .marqee__inner {
    @keyframes marquee {
      0% { left: 0; }
      100% { left: -100%; }
    }
  }

  @-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
  }

  @keyframes spin {
      0% {
          transform: rotate(0deg)
      }
      to {
          transform: rotate(1turn)
      }
  }
  .loader {
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
  }

  .section-label {
    color: ${({ theme }) => theme.colors.estern};
    font-weight: 700;
    font-size: 0.95rem;
    line-height: 0.8rem;
    letter-spacing: 0.19em;
    margin-bottom: 1.25rem;
  }

  .section-featured {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &.mobile {
        margin-bottom: 2rem;
        .col-list__wrapper {
            flex-direction: column;
        }
        .visual-item {
            img {
                width: 100%;
            }
        }
    }
  }
  
  .section-featured a:hover {
      opacity: 0.7;
      text-decoration: none;
  }
  
  .section-featured .visual-item {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      position: relative;
  }
  
  .section-featured .visual-item .play__wrapper {
      position: absolute!important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex!important;
      align-items: center;
      justify-content: center;
  }
  
  .section-featured .visual-item .play__wrapper .play-image {
      width: 5rem;
      height: 5rem;
      margin-top: -3rem;
  }
  
  
  .section-featured .visual-item img {
      border-radius: 10px;
      width: 24rem;
      height: auto;
      margin-bottom: 1rem;
  
  }
  
  .section-featured .visual-item .headline{
      text-align: left;
      color: #34cbe6;
      font-weight: 700;
      text-decoration: underline;
      letter-spacing: -.005rem;
  }
  
  .section-featured .title {
      letter-spacing: -.005rem;
      font-size: 0.7rem;
      line-height: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      color: rgb(115, 122, 150);
  }
  
  .section-featured__list {
      margin: 0;
      padding:0;
      list-style: none;
  }
  .section-featured__list-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1.5rem;
  }
  .dropdown-menu__right .divider {
      border-bottom: 1px solid #d5daf0;
      margin: 2rem 0;
  }
  .section-featured__list-item.columns {
      flex-direction: column;
      margin-right: 6rem;
      margin-top: 0;
  }
  .section-featured__list-item .block {
      flex-direction: column;
      margin-top: 1rem;
  }
  
  .section-featured__list-item .block .block__item {
      display: flex;
      line-height: 1rem;
      letter-spacing: -.005rem;
      color: #00495d;
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 0.8rem;
  }
  .section-featured__list-item .block .block__item .logo {
      color: #4BADAD;
      margin-right: 0.5rem;
      width: 1.2rem;
      font-size: 1rem;
  
  }
  .section-featured__list-item--logo {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgba(0, 206, 224, 0.1);
      color: #2D97B3;
      font-size: 1.1rem;
      margin-right: 0.5rem;
  }
  .mega__dd .dropdown-menu__bubble.double .dropdown-menu__right .section-featured__list-item--logo {
      background: transparent;
      width: unset;
      height: unset;
      color: #4BADAD;
  }
  
  .mega__dd .dropdown-menu__bubble .content .col-list__wrapper  .section-featured__list-item--logo  {
      background: transparent;
      width: unset;
      height: unset;
      color: #4BADAD;
  }
  
  .section-featured__list-item--logo img {
     width: 1.1rem;
     height: auto;
  }
  .section-featured__list-item--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-decoration: none;
  }
  .section-featured__list-item--content .headline {
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 1rem;
      letter-spacing: -.005rem;
      color: #00495d;
      margin-bottom: 0.5rem;
  }
  .section-featured__list-item--content .subheadline {
      line-height: 0.9rem;
      font-size: 0.8rem;
      letter-spacing: -.005rem;
      color: #4BADAD;
      opacity: 0.8;
      text-align: left;
  }
  
  .section-featured__list-item--content ul.subheadline {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      list-style: disc;
  }
  
  .section-featured__list-item--content ul.subheadline li {
    margin-top: 0.3rem;
  }
  
  .col-list__wrapper {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 1rem;
  }
  
  .col-list__wrapper .list__wrapper {
      flex-direction: column;
      align-items: flex-start;
  }
  .col-list__wrapper .list__wrapper li {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      font-weight: 60;
      line-height: 1rem;
      letter-spacing: -.05rem;
      color: #00495d;
  }

`

export default GlobalStyle
