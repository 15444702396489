import React, {useState} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faChevronRight,
  faGift,
  faBriefcase,
  faAddressBook,
  faRocket,
  faCode,
  faBrain,
  faComment,
  faWrench,
  faBook,
  faDownload,
  faAt,
  faBookOpen,
  faChalkboardTeacher,
  faFileVideo,
  faRss,
  faQuestionCircle,
  faCompressArrowsAlt,
  faHeartbeat,
  faHandsHelping,
  faHeadset,
  faShieldAlt,
  faLock,
  faNewspaper,
  faUserMd,
  faFire,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'
import Pulse from 'content/images/pulse.svg'
import Brain from 'content/images/brain.svg'
import Jug from 'content/images/jug.svg'
import Spin from 'content/images/spin.svg'
import Graph from 'content/images/graph.svg'
import Bracket from 'content/images/bracket.svg'

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
  @media (min-width: 1200px) {
    display: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  overflow-y: auto;
  @media (min-width: 1200px) {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  min-height: 100vh;
  width: 95%;
  overflow-y: scroll;
  text-align: left;
  padding: 7rem 1rem 2rem 1rem;
  position: relative;
  right: 0;
  margin-left: auto;
  .nav-drop {
    font-size: 1.5rem;
    color: #00495d;
    font-weight: bold;
    padding-bottom: 1.3rem;
    display: flex;
    justify-content: space-between;
  }
`

const Anchor = styled.a`
    color: white;
    text-decoration: none;
    font-weight: 500;
    opacity: 1;
    margin-left: 2rem;
    color: white;
    &:hover {
        opacity: 0.75;
    }
`

const StyledButton = styled(Link)`
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding: 1rem;  
  background: ${({ theme }) => theme.colors.estern};
  border-radius: 0.375rem;
  color: white;
  text-align: center;
  margin-top: 1rem;
`
const StyledButtonSecondary = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.zodiacLight};
  color: ${({ theme }) => theme.colors.primary};
`


const SideBar = ({ open, setOpen }) => {
  const [openedDropDown, setOpenedDropDown] = useState('')
  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          <div className="nav-drop" onClick={() => openedDropDown === 'product' ? setOpenedDropDown("") : setOpenedDropDown("product")}>
            <span>Product</span>
            <FontAwesomeIcon className="icon-right" icon={faChevronRight} />
          </div>
          {openedDropDown === "product" && (
            <>
              <div className="section-featured mobile" >
                <span className="title">
                  HrFlow.ai FOR...
                </span>
                <ul className="section-featured__list">
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faBriefcase} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/corporates/" className="section-featured__list-item--content">
                      <span className="headline">For Large employers</span>
                      <span className="subheadline">Supercharge your existing HR tools</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faCode} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/developers/" className="section-featured__list-item--content">
                      <span className="headline">For HR Vendors & HrTech</span>
                      <span className="subheadline">Build state-of-the-art AI Powered HR Tech</span>
                    </a>
                  </li>
                </ul>
                <span className="title" style={{marginTop: '1rem'}}>
                  Product
                </span>
                <ul className="section-featured__list">
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faBrain, faAddressBook} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/api/" className="section-featured__list-item--content">
                      <span className="headline">Profile & Job API</span>
                      <span className="subheadline">6 powerful AI layers to bring intelligence into HR data</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faAddressBook} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/sources/" className="section-featured__list-item--content">
                      <span className="headline">Source Connectors</span>
                      <span className="subheadline">Simplify HR data collection across all your channels</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faRocket} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/destinations/" className="section-featured__list-item--content">
                      <span className="headline">Destination Connectors</span>
                      <span className="subheadline">All your workflows made with ease</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="section-featured mobile" >
                <span className="title">
                  AI layers
                </span>
                <ul className="section-featured__list">
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <img src={Bracket} alt="bracket" />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/parsing/" className="section-featured__list-item--content">
                      <span className="headline">Parsing API</span>
                      <span className="subheadline">Automatically turn your HR documents to structured data</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <img src={Spin} alt="spin"/>
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/tagging/" className="section-featured__list-item--content">
                      <span className="headline">Tagging API</span>
                      <span className="subheadline">Automatically enrich and enhance your records</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <img src={Pulse} alt="pulse" />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/searching/" className="section-featured__list-item--content">
                      <span className="headline">Searching API</span>
                      <span className="subheadline">Implement job & profile search that really works</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <img src={Jug} alt="jug"/>
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/scoring/" className="section-featured__list-item--content">
                      <span className="headline">Scoring API</span>
                      <span className="subheadline">The Netflix recommendation engine for profiles & jobs</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <img src={Brain} alt="brain"/>
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/upskilling/" className="section-featured__list-item--content">
                      <span className="headline">Upskilling API</span>
                      <span className="subheadline">Highlight the reasons behind every match</span>
                    </a>
                  </li>
                  <li className="divider"></li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <img src={Graph} alt="graph" />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/embedding/" className="section-featured__list-item--content">
                      <span className="headline">Embedding API</span>
                      <span className="subheadline">Build powerful machine learning apps with pre-trained HR field vector representation</span>
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
          <div className="nav-drop" onClick={() => openedDropDown === 'customers' ? setOpenedDropDown("") : setOpenedDropDown("customers")}>
            <span>Customers</span>
            <FontAwesomeIcon className="icon-right" icon={faChevronRight} />
          </div>
          {openedDropDown === "customers" && (
            <>
              <div className="section-featured mobile">
                <span className="title">
                  Customers
                </span>
                <ul className="section-featured__list">
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faComment} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/customers/" className="section-featured__list-item--content">
                      <span className="headline">Customers stories</span>
                      <span className="subheadline">Learn about our customers</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faWrench} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/use-cases/" className="section-featured__list-item--content">
                      <span className="headline">Use cases</span>
                      <span className="subheadline">Build state-of-the-art AI Powered HR Tech</span>
                    </a>
                  </li>
                </ul>
                <div className="col-list__wrapper">
                  <div className="section-featured__list-item" style={{marginRight: '6rem'}}>
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faBriefcase} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/corporates/" style={{marginTop: '0.5rem'}} className="section-featured__list-item--content">
                      <span className="headline">Corporates</span>
                      <ul className="subheadline">
                        <li>Staffing</li>
                        <li>Headhunting</li>
                        <li>Consulting</li>
                        <li>RPO</li>
                        <li>Fortune 1000</li>
                      </ul>
                    </a>
                  </div>
                  <div className="section-featured__list-item">
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faCode} />
                    </span>
                    <a rel="noopener" href="https://hrflow.ai/developers/" style={{marginTop: '0.5rem'}} className="section-featured__list-item--content">
                      <span className="headline">Developers</span>
                      <ul className="subheadline">
                        <li>Job boards</li>
                        <li>ATS</li>
                        <li>HCM</li>
                        <li>ERP</li>
                        <li>CRM</li>
                        <li>HR Tech</li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>
              <div className="section-featured mobile">
                <span className="title">
                  from our partners
                </span>
                <div className="visual-item">
                  <img className="thumbnail__image--small" src="https://hrflow-ai.imgix.net/custmers.png" alt="thubnail"/>
                  <span className="play__wrapper  wistia_embed wistia_async_5ivj89oryb popover=true popoverContent=link" style={{display: 'inline', position: 'relative'}}>
                    <a href="#"  className="">
                      <img src="https://img.riminder.net/icons/play.svg" alt="play" className="play-image" />
                    </a>
                  </span>
                  
                  <span className="headline">How Riminder.net helps HR departments to deal with GDPR compliance F8 2018 by Caroline Matte</span>
                </div>
              </div>
            </>
          )}
          <div className="nav-drop" onClick={() => openedDropDown === 'ressources' ? setOpenedDropDown("") : setOpenedDropDown("ressources")}>
            <span>Resources</span>
            <FontAwesomeIcon className="icon-right" icon={faChevronRight} />
          </div>
          {openedDropDown === "ressources" && (
            <>
              <div className="section-featured mobile">
                <ul className="section-featured__list">
                  <li className="section-featured__list-item" style={{marginTop: '0'}}>
                    <span className="section-featured__list-item--logo">
                      <FontAwesomeIcon className="icon-right" icon={faBook} />
                    </span>
                    <a href="https://developers.hrflow.ai" target="_blank" rel="noopener" className="section-featured__list-item--content">
                      <span className="headline">Documentation</span>
                      <span className="subheadline">Start integrating HrFlow.ai now</span>
                    </a>
                  </li>
                </ul>
                <div className="col-list__wrapper">
                  <div className="section-featured__list-item columns">
                    <span className="title" style={{marginTop: '1rem'}}>GETTING STARTED</span>
                    <div className="block">
                      <a href="https://developers.hrflow.ai/reference/" rel="noopener" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faDownload} />
                        </span>
                        <span className="headline">Installation</span>
                      </a>
                      <a href="https://developers.hrflow.ai/docs/api-authentification" rel="noopener" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faAt} />
                        </span>
                        <span className="headline">Authentification</span>
                      </a>
                      <a href="https://developers.hrflow.ai/docs/quick-start" rel="noopener" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faBookOpen} />
                        </span>
                        <span className="headline">Quick start</span>
                      </a>
                    </div>
                  </div>
                  <div className="section-featured__list-item columns">
                      <span className="title" style={{marginTop: '1.5rem'}}>LEARN</span>
                      <div className="block">
                        <a href="https://developers.hrflow.ai/docs/quick-start" rel="noopener" target="_blank" className="block__item">
                          <span className="logo">
                            <FontAwesomeIcon className="icon-right" icon={faChalkboardTeacher} />
                          </span>
                          <span className="headline">Tutorials</span>
                        </a>
                        {/* <a href="https://app.livestorm.co/hrflow" target="_blank" rel="nofollow" className="block__item">
                          <span className="logo">
                            <FontAwesomeIcon className="icon-right" icon={faFileVideo} />
                          </span>
                          <span className="headline">Webinars </span>
                        </a> */}
                        <a href="https://updates.hrflow.ai/" target="_blank" rel="noopener" className="block__item">
                          <span className="logo">
                            <FontAwesomeIcon className="icon-right" icon={faGift} />
                          </span>
                          <span className="headline">Updates </span>
                        </a>
                        <a href="//blog.hrflow.ai/" target="_blank"  rel="noopener" className="block__item">
                          <span className="logo">
                            <FontAwesomeIcon className="icon-right" icon={faRss} />
                          </span>
                          <span className="headline">Blog </span>
                        </a>
                        <a href="http://https://help.hrflow.ai/en/" target="_blank" rel="noopener" className="block__item">
                          <span className="logo">
                            <FontAwesomeIcon className="icon-right" icon={faQuestionCircle} />
                          </span>
                          <span className="headline">Help Center </span>
                        </a>
                      </div>
                  </div>
                </div>
              </div>
              <div className="section-featured mobile">
                <span className="title">
                  GETTING STARTED
                </span>
                <ul className="section-featured__list">
                  <li className="section-featured__list-item">
                    <span className="section-featured__list-item--logo plain">
                      <FontAwesomeIcon className="icon-right" icon={faCompressArrowsAlt} />
                    </span>
                    <a href="https://developers.hrflow.ai/reference/" target="_blank" rel="noopener" className="section-featured__list-item--content">
                      <span className="headline">Full API Reference</span>
                    </a>
                  </li>
                  <li className="section-featured__list-item" style={{marginTop: '0.5rem'}}>
                    <span className="section-featured__list-item--logo plain">
                      <FontAwesomeIcon className="icon-right" icon={faHeartbeat} />
                    </span>
                    <a href="https://status.hrflow.ai" target="_blank" rel="noopener" className="section-featured__list-item--content">
                      <span className="headline">API Status</span>
                    </a>
                  </li>
                </ul>
                <a href="https://github.com/riminder" target="_blank" className="visual-item">
                  <img src="https://s3.amazonaws.com/img.riminder.net/seo/riminder-artificial-intelligence-for-human-resource.png" alt="Main SEO Image"/>
                  <span className="headline">Check out the HrFlow.ai repositories</span>
                </a>
              </div>
            </>
          )}
          <div className="nav-drop" onClick={() => openedDropDown === 'company' ? setOpenedDropDown("") : setOpenedDropDown("company")}>
            <span>Company</span>
            <FontAwesomeIcon className="icon-right" icon={faChevronRight} />
          </div>
          {openedDropDown === "company" && (
            <>
              <div className="section-featured mobile">
                <div className="col-list__wrapper" style={{marginTop: 0}}>
                  <div className="section-featured__list-item columns">
                    <span className="title">ABOUT US</span>
                    <div className="block">
                      <a rel="noopener" href="https://hrflow.ai/about-us/" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faBuilding} />
                        </span>
                        <span className="headline">About us</span>
                      </a>
                      <a rel="noopener" href="https://hrflow.ai/awards/" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faUserMd} />
                        </span>
                        <span className="headline">Awards</span>
                      </a>
                      <a rel="noopener" href="https://hrflow.ai/careers/" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faUserMd} />
                        </span>
                        <span className="headline">Careers</span>
                      </a>
                      <a href="//blog.hrflow.ai" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faRss} />
                        </span>
                        <span className="headline">Blog</span>
                      </a>
                      <a rel="noopener" href="https://hrflow.ai/press/" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faNewspaper} />
                        </span>
                        <span className="headline">Newsroom</span>
                      </a>
                      <a href="//security.hrflow.ai" rel="noopener" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faLock} />
                        </span>
                        <span className="headline">Security</span>
                      </a>
                      <a href="//gdpr.hrflow.ai/" target="_blank" rel="noopener" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faShieldAlt} />
                        </span>
                        <span className="headline">Privacy & terms</span>
                      </a>
                      <a href="//github.com/riminder/" target="_blank" className="block__item">
                        <span className="logo">
                          <FontAwesomeIcon className="icon-right" icon={faCode} />
                        </span>
                        <span className="headline">Open Source</span>
                      </a>
                    </div>
                  </div>
                  <div className="section-featured__list-item columns">
                      <span className="title">Support</span>
                      <div className="block">
                        <a href="http://https://help.hrflow.ai/en/" target="_blank" rel="noopener" className="block__item">
                          <span className="logo">
                              <i className="far fa-question-circle"></i>
                              <FontAwesomeIcon className="icon-right" icon={faQuestionCircle} />
                          </span>
                          <span className="headline">Help Center</span>
                        </a>
                        <a href="#" id="intercLuncher" className="block__item">
                          <span className="logo">
                            <FontAwesomeIcon className="icon-right" icon={faHeadset} />
                          </span>
                          <span className="headline">Contact us</span>
                        </a>
                      </div>
                      <span className="title" style={{marginTop: '1.5rem'}}>PARTNERS</span>
                      <div className="block">
                          
                          <a rel="noopener" href="https://hrflow.ai/partners/" className="block__item">
                            <span className="logo">
                                <FontAwesomeIcon className="icon-right" icon={faHandsHelping} />
                            </span>
                            <span className="headline">Partner with us </span>
                          </a>
                      </div>
                  </div>
                </div>
              </div>
              <div className="section-featured mobile">
                <span className="title">
                  From the blog
                </span>
                <a target="_blank" rel="nofollow"  href="https://techcrunch.com/2018/05/25/riminder-raises-2-3-million-for-its-ai-recruitment-service/" className="visual-item">
                  <img src={"https://hrflow-ai.imgix.net/tc-cover.jpg?fit=crop&w=384&h=255"} alt="technrunch"/>
                  <span className="headline">Riminder raises $2.3 million for its AI recruitment service</span>
                </a>
              </div>
            </>
          )}
          <StyledButton rel="noopener" href="https://hrflow.ai/signup/">SIGN UP</StyledButton>
          <StyledButtonSecondary rel="noopener" href="https://hrflow.ai/book-us/">BOOK A DEMO</StyledButtonSecondary>
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} />
    </>
  )
}

SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default SideBar